import React from 'react';
import {
  FiSettings,
  FiUser,
  FiCompass,
  FiBox,
  FiCrosshair,
  FiCamera,
  FiDollarSign,
  FiPlus,
} from 'react-icons/fi';
import Dashboard from '@material-ui/icons/Dashboard';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ContactsIcon from '@material-ui/icons/Contacts';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import AssessmentIcon from '@material-ui/icons/Assessment';

import { NavLink } from 'react-router-dom';
import { FaRoute } from 'react-icons/fa';
import { Container } from './styles';
import { useMenu } from '../../hooks/menu';

import Dropdown from './Dropdown';

const ListMenu = () => {
  const { expansed, expansedFalse } = useMenu();
  const perfil = sessionStorage.getItem('perfil');

  const conditionOffMenu =
    perfil === 'admin' || perfil === 'admin_global'|| perfil === 'ti';

  const conditionOffMenuTi = perfil === 'ti';
  const conditionOffMenuAdminGlobal = perfil === 'admin_global';
  function closemenu() {
    if (window.screen.width <= 500) {
      expansedFalse();
    }
  }

  return (
    <Container isExpansed={expansed}>
      <li>
        <NavLink
          to="/clientes"
          onClick={() => {
            closemenu();
          }}
        >
          <SupervisorAccountIcon size={18} />
          <p>Clientes</p>
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/produtos"
          onClick={() => {
            closemenu();
          }}
        >
          <ShoppingBasketIcon size={18} />
          <p>Produtos</p>
        </NavLink>
      </li>

      <li>
        <NavLink
          to="/carrinho"
          onClick={() => {
            closemenu();
          }}
        >
          <AddShoppingCartIcon size={18} />
          <p>Carrinho</p>
        </NavLink>
      </li>

      <li>
        <NavLink
          to="/relatorios"
          onClick={() => {
            closemenu();
          }}
        >
          <AssessmentIcon size={18} />
          <p>Relatórios</p>
        </NavLink>
      </li>

      <li>
        <NavLink
          to="/pedidos"
          onClick={() => {
            closemenu();
          }}
        >
          <ContactsIcon size={18} />
          <p>Pedidos</p>
        </NavLink>
      </li>
      {conditionOffMenu && (
        <Dropdown icon={FiSettings} title="Configurações">
          <li>
            <NavLink
              to="/admin/usuarios"
              onClick={() => {
                closemenu();
              }}
            >
              <FiUser size={18} />
              <p>Usuários</p>
            </NavLink>
          </li>

          {!conditionOffMenuTi && (
          <>
          <li>
            <NavLink
              to="/admin/organizacao"
              onClick={() => {
                closemenu();
              }}
            >
              <FiCompass size={18} />
              <p>Organização</p>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/empresa"
              onClick={() => {
                closemenu();
              }}
            >
              <FiCrosshair size={18} />
              <p>Empresa</p>
            </NavLink>
          </li></>
          )}
          <li>
            <NavLink
              to="/admin/galeria"
              onClick={() => {
                closemenu();
              }}
            >
              <FiCamera size={18} />
              <p>Galeria de imagens</p>
            </NavLink>
          </li>
          {conditionOffMenuAdminGlobal && (
            <>
              <li>
                <NavLink
                  to="/admin/precos"
                  onClick={() => {
                    closemenu();
                  }}
                >
                  <FiDollarSign size={18} />
                  <p>Lista de preços</p>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/regras"
                  onClick={() => {
                    closemenu();
                  }}
                >
                  <FiBox size={18} />
                  <p>Regras de negócio</p>
                </NavLink>
              </li>
            </>
          )}
        </Dropdown>
      )}
    </Container>
  );
};

export default ListMenu;
/*
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import ContactsIcon from "@material-ui/icons/Contacts";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import AssessmentIcon from '@material-ui/icons/Assessment';
import ArrowRight from "@material-ui/icons/ArrowRight";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import Produtos from "views/Produtos/Produtos.js";
import Clientes from "views/Clientes/Clientes.js";
import Pedidos from "views/Pedidos/Pedidos.js";
import Carrinho from "views/Carrinho/Carrinho";
import Relatorios from "views/Relatorios/relatorios.js";
//import Relatorio1 from "views/Relatorios/EGR1000/EGR1000.js";
//import Relatorio2 from "views/Relatorios/EIR6000/EIR6000.js";
//import Relatorio3 from "views/Relatorios/EIR4002/EIR4002.js";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    key: "0",
    rtlName: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
    click: "open"
  },
  {
    path: "/table",
    name: "Clientes",
    key: "1",
    rtlName: "Clientes",
    icon: SupervisorAccountIcon,
    component: Clientes,
    layout: "/admin",
    click: "open"
  },
  {
    path: "/produtos",
    name: "Produtos",
    key: "2",
    rtlName: "Produtos",
    icon: ShoppingBasketIcon,
    component: Produtos,
    layout: "/admin",
    click: "open"
  },
  {
    path: "/carrinho",
    name: "Carrinho",
    key: "3",
    rtlName: "Carrinho",
    icon: AddShoppingCartIcon,
    component: Carrinho,
    layout: "/admin",
    click: "open"
  }, {
    path: "/relatorios",
    name: "Relatórios",
    key: "5",
    rtlName: "Relatorio2",
    icon: AssessmentIcon,
    component: Relatorios,
    layout: "/admin",
    click: "open",
  },
 
  {
    path: "/pedidos",
    name: "Pedidos de Venda",
    key: "4",
    rtlName: "Pedidos",
    icon: ContactsIcon,
    component: Pedidos,
    layout: "/admin",
    click: "open"
  }

];

export default dashboardRoutes;
 */
